* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: Helvetica, Arial, sans-serif;
	scroll-behavior: smooth;
}

.navbar {
  background: #131313;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar__container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 50px;
}

.navIcon{
	color: white;
}

#navbar__logo{
	background-color: #ff8177;
	background-image: linear-gradient(to top, #C9D6FF, #E2E2E2);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	font-size: 32px;
	font-weight: bold;
}

.navbar__menu {
  display: flex;
  align-items: center;
  /* list-style: none; */
}

.navbar__item {
  height: 80px;
}

.navbar__links {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  text-decoration: none;
  height: 100%;
  transition: all 0.3s ease;
}
.navbar__btn{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1rem;
	width: 100%;
}

.button{
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 10px 20px;
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
	border-radius: 4px;
	background: #833ab4;
	background: -webkit-linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
	background: -moz-linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
	background: linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
	color: #fff;
	transition: all 0.3s ease;
}

.navbar__links:hover{
	color: #c471ed;
	transition: all 0.3s ease;
}

.button:hover{
	color: #000;
	transition: all 0.3s ease;
}
.navbar__toggle span{
	position: absolute;
	top: -1000px;
}

@media screen and (max-width: 1020px){
	.navbar{
		display:flex;
	}
	.navbar__container {
    	display: flex;
		flex-direction: row;
    	justify-content: space-between;
    	height: 80px;
    	z-index: 1;
    	max-width: 1300px;
   		padding: 0;
  }
	.navbar__menu{
		display: grid;
   	 	grid-template-columns: auto;
    	margin: 0;
    	width: 100%;
    	position: absolute;
    	top: -1000px;
    	opacity: 1;
    	transition: all 0.5s ease;
    	z-index: -1;
			background: #131313;
    	top: 100%;
    	opacity: 1;
    	transition: all 0.5s ease;
    	z-index: 99;
   	 	height: 60vh;
    	font-size: 1.6rem;
	}

	.navbar__menu .active{
		background: #131313;
    	top: 100%;
    	opacity: 1;
    	transition: all 0.5s ease;
    	z-index: 99;
   	 	height: 60vh;
    	font-size: 1.6rem;
	}

	#navbar__logo{
		padding-left: 25px;
		padding-right: 50px
	}


	.navbar__item {
    	width: 100%;
 	 }
	.navbar__links {
    	text-align: center;
    	padding: 2rem;
    	width: 100%;
    	display: table;
 	 }

	.navbar__btn {
    	padding-bottom: 2rem;
  	}

	.button{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		height: 80px;
		margin: 0;
	}

	#mobile-menu{
		position: absolute;
		top: 21.5%;
		right: 5%;
		transform: translate(5%, 21.5%);
	}
	
	.navbar__toggle span{
		display: block;
		position: relative;
		float: left;
		cursor: pointer;
		padding-top: 6px;
		transform: scale(1.5);
		top: auto;
		transition: all 0.3s ease;
	}

		
	#mobile-menu.is-active .bar:nth-child(2){
		opacity: 0;
	}
	#mobile-menu.is-active .bar:nth-child(1){
		transform: translateY(8px) rotate(45deg);
	}
	#mobile-menu.is-active .bar:nth-child(3){
		transform: translateY(-8px) rotate(-45deg);
	}
}

/* Home Section */
#root{
	width: 100%;
	height: 100%;
}

.home-section{
	background: #000000;
	background: linear-gradient(to right, #161616, #000000);
	padding: 235px 0;
	min-height: calc(100vh - 80px);
}

.home__container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	height: 90%;
	text-align: center;
	padding: 30px;
}
.home__container a{
	padding: 1em;
	padding-left: 3em;
	padding-right: 3em;     
   	margin: -1em;
	margin-left: -3em;
	margin-right: -3em;

}

.home__heading{
	font-size: 100px;
	margin-bottom: 24px;
	color: #fff;
}

.home__heading span{
	background: #da22ff;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #9114ff, #da22ff);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #8f0eff, #da22ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

.home__description{
	font-size: 48px;
	background: #FC5C7D;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #6A82FB, #FC5C7D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	margin: 12px;
}

.highlight {
	border-bottom: 4px sold rgb(132, 0, 255);
}

@media screen and (max-width: 768px){
	.home__heading {
		font-size: 60px;
	}

	.home__description{
		font-size: 28.8px;
	}
}

/*About Section*/
.main{
	background-color: #131313;
	padding: 6rem 0;
	min-height: calc(100vh - 80px);
}

.main__container{
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	height: 90%;
	z-index: 1;
	width: 100%;
	max-width: 1100px;
	padding: 0 50px;
}

.main__content{
	color: #fff;
	width: 100%;
}
.main__content h1{
	background-color: #6a3093;
	background-image: linear-gradient(to top, #6a3093, #a044ff);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	text-transform: uppercase;
	margin-bottom: 16px;
	font-size: 20px;
}

.main__content ul{
	margin-left: 20px;
	top: 50px;
	list-style-position: inside;
	display: inline-block;


}

.main__content p{
	margin-top: 12px;
	font-size: 14px;
	color: #fff;
}

.main__btn{
	font-size: 16px;
	background: #833ab4;
	background: -webkit-linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
	background: -moz-linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
	background: linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
	padding: 15px 45px;
	border: none;
	border-radius: 4px;
	margin-top: 24px;
	cursor: pointer;
 	position: relative;
  	transition: all 0.35s;
  	outline: none;
}

.main__btn a{
	position: relative;
	z-index: 2;
	color: #fff;
	text-decoration: none;
		padding: 1em;
		padding-left: 3em;
		padding-right: 3em;     
		   margin: -1em;
		margin-left: -3em;
		margin-right: -3em;
}

.main__btn:after{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background: #ff1ead;
	transition: all 0.35s;
	border-radius: 4px;

}

.main__btn:hover {
	color: #fff;
}

.main__btn:hover:after{
	width: 100%;
}

.main__img--container {
  text-align: center;
	position: relative;
	top: -16.5px;
}


.main__img--card {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 2.5px;
	padding-right: 2.5px;
	margin-top: -50px;
	margin: 20px;
	height: 900px;
	width: 585.5px;
	border-radius: 4px;
	display: flex; 
	justify-content: center;
	align-items: center;
	color: #fff;
	background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
	z-index: -3;
	-moz-transform: scale(0.66);
	zoom: 0.66;

}

.pic-of-me{
	height: 875px;
	width: 561.5px;
	z-index: 3;
	-moz-box-shadow:    inset 0 0 10px #000000;
	-webkit-box-shadow: inset 0 0 10px #000000;
	box-shadow:         inset 0 0 10px #000000;
	zoom: 1;
	position: absolute;
		overflow: hidden;

}

.image--container {
	height: 875px;
	-webkit-box-shadow: inset 0 0 10px #000;    
	  -moz-box-shadow: inset 0 0 10px #000;
	  box-shadow: inset 0 0 10px #000;

}
.image--container img{
	height: 875px;
	width: 561.5px;
	position: relative;
	z-index: -2;
}

#card-2{
	background: #8E2DE2;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */	
}

/* Mobile Response */

@media screen and (max-width: 1100px){
	.main__container{
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin: 0 auto;
		height: 90%;
	}

	.main__img--container{
		display: flex;
		justify-content: center;
		z-index: inherit;
	}
	.main__img--card{
		zoom: 0.7;
    	-moz-transform: scale(0.7);
	}

	.main__content{
		text-align: center;
		margin-bottom: 24px;
	}

	.main__content h1{
		font-size: 30px;
		margin-top: 24px
	}
	.main__content p{
		margin-top: 12px;
		font-size: 18px;

	}
	.main__content ul{
		text-align: left;
	}

}

@media screen and (max-width: 550px){
	.main__img--card{
		zoom: 0.5;
    -moz-transform: scale(0.5);
	}
	
	.main__content h1{
		margin-top: 8px;
		font-size: 24px;
		margin-bottom: 12px;
	}

	.main__content p{
		margin-top: 0px;
		font-size: 18px;
	}

	.main__btn{
		padding: 12px 36px;
		margin: 32px 0;
	}
}

.work{
	background: #131313;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 3rem 0;
	min-height: calc(100vh - 80px);
}

.work h1{
	color:#fff;
	margin-bottom: 50px;
	font-size: 60px;
}


.work h2{
	display: grid;
	justify-content: center;
	color: #fff;
	font-size: 1.6rem;
	padding-bottom: 10px;
}


.work__wrapper h2{
	display: grid;
	justify-content: center;
	color: #fff;
	font-size: 2rem;
	padding-bottom: 10px;
}

.photos{
	width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 10px;
		text-align: center;
}
.photos img{
	width: 65%;
}


.videos{
	display: flex;
	justify-content: center;
	align-items: center;
}

.videos iframe{
	width: 720px;
	height: 480px;
}


@media screen and (max-width: 1440px){
	.work__wrapper{
		width: 75%;
	}

	.photos img{
		max-width: 100%;
	}
	.photos{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 10px;
		/* background: #fff; */
		text-align: center;
		}

	}

	.main ul,li { list-style-type: none;
        list-style-position:inside;
        margin:0;
        padding:0; }
	
.contact{
	background: #131313;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 3rem 0;
	min-height: calc(100vh - 80px);
}

.contact > h1{
	color: #fff;
	margin-bottom: 50px;
	font-size: 60px;
}